<template>
  <div id="recent-salary-submissions" class="bg-gray-main">
    <div class="container block py-16">
      <h1 class="text-3xl font-bold">Recent Salary Submissions</h1>
      <small class="font-thin">Salaries are per annum</small>
      <div
        class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-7 mt-12 gap-6 recent-salary-submission-grid"
      >
        <div
          class="flex items-center text-center justify-center align-center rounded-md border border-dashed bg-gray-diff cursor-pointer"
          @click="() => $router.push({ name: 'Add Salary' })"
        >
          <div>
            <font-awesome-icon
              :icon="['fas', 'plus-circle']"
              size="3x"
              :style="{ color: '#5F4CA5' }"
            />

            <p class="mt-2">Add yours</p>
          </div>
        </div>
        <template v-for="(salary, index) in recentSalaryData">
          <div
            class="rounded-md border border-transparent bg-white shadow recent-salary-submission-card"
            :key="index"
          >
            <header class="py-4 px-4 font-bold">
              <span class="text-2xl text-purple"
                >₦ {{ formatSalary(salary.salaryYearly) }}</span
              >
            </header>
            <hr />
            <div class="py-4 px-4">
              <div>
                <small class="text-xs text-black-light font-light"
                  >Company</small
                >
                <p>{{ salary.companyName }}</p>
              </div>
              <div>
                <small class="text-xs text-black-light">Location</small>
                <p>{{ salary.jobLocation }}</p>
              </div>
              <div>
                <small class="text-xs text-black-light font-light"
                  >Posted</small
                >
                <p>{{ getTimeDifference(salary.createdAt) }}</p>
              </div>
            </div>
          </div>
        </template>
        <div
          class="flex items-center text-center justify-center align-center rounded-md border border-dashed bg-gray-diff cursor-pointer"
          @click="() => $router.push({ name: 'Salaries' })"
        >
          <div>
            <font-awesome-icon
              :icon="['fas', 'arrow-circle-right']"
              size="3x"
              :style="{ color: '#5F4CA5' }"
            />

            <p class="mt-2">View More</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { FILTERS } from "@/helpers/filters.js";

export default {
  name: "RecentSalarySubmissions",
  props: {
    recentSalaryData: {
      type: Array,
      required: true,
    },
  },
  methods: {
    getTimeDifference(pastDate) {
      let now = moment();
      let past = moment(pastDate);

      let difference = now.diff(past);

      let tempTime = moment.duration(difference);

      let hours = tempTime.hours();
      let minutes = tempTime.minutes();
      let days = tempTime.days();

      if (hours < 1 && days < 1) return `${minutes} minutes ago`;

      if (days >= 1) return `${days} days ago`;

      if (hours >= 1) return `${hours} hours ago`;
    },
    formatSalary(value) {
      return FILTERS.formatTotalSalary(value);
    },
  },
};
</script>

<style lang="scss" scoped>
.recent-salary-submission-grid {
  > div {
    min-height: 243px;
    max-width: 178px;
  }
}

hr {
  @apply border;
  @apply border-purple;
  @apply border-b-0;
  @apply border-r-0;
  @apply border-dashed;
  @apply border-l-0;
}

.recent-salary-submission-card:nth-last-of-type(5) {
  @media screen and (max-width: 1024px) {
    display: none;
  }
}
</style>