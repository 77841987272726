<template>
  <button
    class="transition disabled:opacity-50 focus:outline-none duration-200 ease-in-out border border-transparent rounded px-5 py-4"
    :class="getClass"
    :disabled="disabled"
    @click="$emit('click')"
    @mouseover="hoverFontAwesomeStyle()"
    @mouseleave="defaultFontAwesomeStyle()"
  >
    <template v-if="icon">
      <font-awesome-icon :icon="icon" size="lg" :style="fontAwesomeStyle" />
    </template>
    <template v-if="loading">
      <font-awesome-icon
        :style="fontAwesomeStyle"
        icon="spinner"
        class="text-purple"
        spin
      />
    </template>

    {{ text }}
  </button>
</template>

<script>
export default {
  name: "Button",
  props: {
    id: String,
    type: String,
    text: {
      type: String,
      required: true,
    },
    variant: {
      type: String,
      default: "primary",
    },
    hasShadow: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false
    },
    icon: Array,
    custom_class: String,
  },
  mounted() {
    this.defaultFontAwesomeStyle();
  },
  computed: {
    getClass() {
      let classObj = { shadow: this.hasShadow };
      //   Add Variant to Class
      classObj[this.variant] = true;
      //   Add Custom Passed Class
      classObj[this.custom_class] = true;
      return classObj;
    },
  },
  methods: {
    defaultFontAwesomeStyle() {
      if (this.variant == "secondary")
        return (this.fontAwesomeStyle = {
          color: "#5F4CA5",
          marginRight: "2px",
        });
      return (this.fontAwesomeStyle = { color: "#fff", marginRight: "2px" });
    },
    hoverFontAwesomeStyle() {
      if (this.variant == "secondary")
        return (this.fontAwesomeStyle = { color: "#fff", marginRight: "2px" });
      return (this.fontAwesomeStyle = { color: "#5F4CA5", marginRight: "2px" });
    },
  },
  data() {
    return {
      fontAwesomeStyle: {},
    };
  },
};
</script>

<style scoped lang="scss">
.secondary {
  @apply border-purple;
  @apply text-purple;
}

.secondary:hover:enabled {
  @apply bg-purple;
  @apply text-white;
}

.primary {
  @apply bg-purple;
  @apply text-white;
}

.primary:hover:enabled {
  @apply border-purple;
  @apply bg-white;
  @apply text-purple;
}

button:disabled,
button[disabled]{
  @apply bg-opacity-30;
}

</style>