import axios from 'axios';
// import router from '../router'
// import Vue from 'vue';

// export const baseURL = 'http://localhost:8005';
export const baseURL = 'https://backend.saalari.com';

export const http = axios.create({ baseURL})

export const setupHttp = () => {
    http.interceptors.response.use(response => {
        return response;
    }, error => {
        if (error.response.status === 403) {
            // Handle all 403 error here

            // router.push({ name: "Login" });
        } else {
            // handle other 4xx errors

            // let errorData = error.response.data;
            // Vue.$toast.open({
            //     message: errorData.message,
            //     type: 'error',
            // });
        }
        return Promise.reject(error);
    });
}
