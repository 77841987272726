import axios from 'axios';

const API_URL = "https://dev-backend.saalari.com/api";

const state = {
    job: {}
}

const getters = {
    job: (state) => state.job
}

const actions = {
    async fetchJob(context,id){
        let url = `${API_URL}/user/salaries/by-job-family?jobFamily=${id}&numBars=10&percentiles=25,50,75,90&currency=ngn`;

        const response = await axios.get(url);

        console.log(response.data);
        
        context.commit("setJob", response.data);
    }
}

const mutations = {
    setJob: (state, job) => (state.job = job),
}


export default {state,getters,actions,mutations}