<template>
  <ValidationObserver ref="fieldButtonForm">
    <form @submit.prevent="submitData">
      <validation-provider :rules="rules" v-slot="{ errors }">
        <div
          class="bg-gray-light flex p-2 pl-4 w-full rounded md:rounded-md shadow"
        >
          <input
            v-model="inputValue"
            :type="type"
            class="w-full md:w-8/12 focus:outline-none bg-gray-light"
            :placeholder="placeholder"
            ref="input"
          />
          <Button
            :text="button_text"
            custom_class="hidden md:block text-sm flex-grow"
            :hasShadow="false"
            :loading="loading"
          />
        </div>
        <div class="error pt-1" v-if="errors.length">
          <small
            class="text-red-500 text-sm"
            v-for="(error, index) in errors"
            :key="index"
            >{{ error }}</small
          >
        </div>
      </validation-provider>
    </form>
  </ValidationObserver>
</template>

<script>
import Button from "@/components/core/Button";

export default {
  name: "FieldButtonCombo",
  props: {
    placeholder: String,
    button_text: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: String,
      default: "required"
    }
  },
  data() {
    return {
      inputValue: "",
    };
  },
  emits: ["data-submitted"],
  components: { Button },
  methods: {
    submitData(e) {
      this.$refs.fieldButtonForm.validate().then((success) => {
        if (!success) {
          return;
        }

        this.$emit("data-submitted", this.inputValue);
        this.inputValue = "";

        // Wait until the models are updated in the UI
        this.$nextTick(() => {
          if(this.$refs.fieldButtonForm) this.$refs.fieldButtonForm.reset();
        });
      });
    },
    updateValue() {
      this.$emit("input", this.$refs.input.value);
    },
  },
};
</script>
