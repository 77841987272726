<template>
  <div>
    <Header />
    <router-view />
    <div class="mb-16">
      <NewsLetterCard />
    </div>
    <Footer />
  </div>
</template>

<style lang="scss">
</style>

<script>
import Header from "@/components/layouts/Header.vue";
import Footer from "@/components/layouts/Footer.vue";
import NewsLetterCard from "@/components/derived/NewsletterCard";

export default {
  name: "Home",
  metaInfo: {
    title:
      "Saalari - How much does a Sales Manager in Chevron make in Nigeria?",
    meta: [
      {
        name: "description",
        content: "How much does a Sales Manager in Chevron make in Nigeria?",
      },
      {
        property: "og:type",
        content: "website",
      },
      {
        name: "robots",
        content: "index,follow",
      },
    ],
  },
  components: {
    Header,
    Footer,
    NewsLetterCard,
  },
};
</script>
