const moment = require("moment");

export const levels = (key, valuesOnly, namesOnly) => {
  const LEVELS = {
    ["[0, 3]"]: "Junior: 0-3 years",
    ["[3, 7]"]: "Mid-level: 3-7 years",
    ["[8, 15]"]: "Senior: 8- 15 years",
    null: "Executive- C-Level role.",
  };
  if (valuesOnly) return Object.keys(LEVELS);
  if (namesOnly) return Object.values(LEVELS);
  return key ? LEVELS[key] : LEVELS;
};

export const filterTimeRange = () => {
  let format = "YYYY-MM-DD HH:mm:ss";

  //  last (month|year) (start|end)
  let lms = moment()
    .subtract(1, "months")
    .startOf("month")
    .format(format);
  let lme = moment()
    .subtract(1, "months")
    .endOf("month")
    .format(format);
  let lys = moment()
    .subtract(1, "years")
    .startOf("year")
    .format(format);
  let lye = moment()
    .subtract(1, "years")
    .endOf("year")
    .format(format);

  const RANGES = {
    [`${lms}|${lme}`]: "Past Month",
    [`${lys}|${lye}`]: "Past Year",
    null: "All Time",
  };
  return RANGES;
};

export const jobFamilies = (key, groupKey) => {
  const JOB_FAMILIES = {
    0: {
      0: "Business Development",
      1: "Sales Development",
      2: "Account Executive",
      3: "BD Manager",
      4: "Account Manager",
      5: "Sales Manager",
    },

    1: {
      6: "Chief Executive Officer",
      7: "Chief Financial Officer",
      8: "Chief Marketing Officer/Marketing Director",
      9: "Chief Operating Officer",
      10: "Chief Technology Officer",
    },

    2: {
      11: "H.R.",
      12: "Office Manager",
      13: "Recruiter",
      14: "Customer Service",
      15: "Operations Manager",
      16: "Finance/Accounting",
      17: "Legal",
    },

    3: {
      18: "Growth Hacker",
      19: "Marketing Manager",
      20: "Content Creator",
    },

    4: {
      21: "Business Analyst",
      22: "Data Scientist",
      23: "Product Manager",
      24: "Project Manager",
      25: "Legal",
    },

    5: {
      26: "Mobile Developer",
      27: "Frontend Developer",
      28: "Backend Developer",
      29: "Full-Stack Developer",
      30: "Engineering Manager",
      31: "QA Engineer",
      32: "DevOps",
      33: "Software Architect",
    },

    6: {
      34: "UI/UX Designer",
      35: "User Researcher",
      36: "Visual Designer",
      37: "Creative Director",
    },
  };

  let valuesToReturn = JOB_FAMILIES;
  if (groupKey) valuesToReturn = valuesToReturn[groupKey];
  if (groupKey && key) valuesToReturn = valuesToReturn[key];
  else if (key) {
    Object.keys(JOB_FAMILIES).some((jfKey) => {
      if (typeof valuesToReturn !== "string")
        valuesToReturn = JOB_FAMILIES[jfKey][key];
    });
  }
  return valuesToReturn;
};

export const getJobFamilyNameFromChildId = (childKey) => {
  if (childKey <= 5) return "Sales";
  if (childKey <= 10) return "Management";
  if (childKey <= 17) return "Operations";
  if (childKey <= 25) return "Other Roles";
  if (childKey <= 33) return "Software Engineering";
  return "Design";
};

export const jobfamilyGroups = (key, valuesOnly, namesOnly) => {
  const JOB_FAMILY_GROUPS = {
    0: "Sales",
    1: "Management",
    2: "Operations",
    3: "Marketing",
    4: "Other Roles",
    5: "Software Engineering",
    6: "Design",
  };
  if (valuesOnly) return Object.keys(JOB_FAMILY_GROUPS);
  if (namesOnly) return Object.values(JOB_FAMILY_GROUPS);
  return key ? JOB_FAMILY_GROUPS[key] : JOB_FAMILY_GROUPS;
};

export const genders = (key) => {
  const GENDERS = {
    1: "Male",
    2: "Female",
    3: "Other",
  };
  return key ? GENDERS[key] : GENDERS;
};

/*
 * Levels
 */
export const getLevels = () => {
  const data = [
    {
      name: "Junior: 0-3 Years of Experience",
      value: 0,
    },
    {
      name: "Mid-level: 3-7 Years of Experience",
      value: 1,
    },
    {
      name: "Senior: 8-15 Years of Experience",
      value: 2,
    },
    {
      name: "Executive: C-Level Role",
      value: 3,
    },
  ];
  return data;
};

/*
 * Gender
 */
export const getGender = () => {
  const data = [
    {
      name: "Select Gender",
      value: "",
    },
    {
      name: "Male",
      value: 1,
    },
    {
      name: "Female",
      value: 2,
    },
    {
      name: "Other",
      value: 3,
    },
  ];
  return data;
};

/** Currencies */
export const getCurrencies = () =>
  [
    {
      name: "USD ($)",
      value: "usd",
    },
    {
      name: "Naira (₦)",
      value: "ngn"
    }
  ];

/*
 * Job Familes For Select
 *
 */
export const getJobFamilies = () => {
  const data = {
    Sales: [
      { name: "Business Development", value: 0 },
      { name: "Sales Development", value: 1 },
      { name: "Account Executive", value: 2 },
      { name: "BD Manager", value: 3 },
      { name: "Account Manager", value: 4 },
      { name: "Sales Manager", value: 5 },
    ],

    Management: [
      {
        name: "Chief Executive Officer",
        value: 6,
      },
      {
        name: "Chief Financial Officer",
        value: 7,
      },
      {
        name: "Chief Marketing Officer / Marketing Director",
        value: 8,
      },
      {
        name: "Chief Operating Officer",
        value: 9,
      },
      {
        name: "Chief Financial Officer",
        value: 10,
      },
    ],

    Operations: [
      {
        name: "H.R.",
        value: 11,
      },
      {
        name: "Office Manager",
        value: 12,
      },
      {
        name: "Recruiter",
        value: 13,
      },
      {
        name: "Customer Service",
        value: 14,
      },
      {
        name: "Operations Manager",
        value: 15,
      },
      {
        name: "Finance/Accounting",
        value: 16,
      },
      {
        name: "Legal",
        value: 17,
      },
    ],

    Marketing: [
      {
        name: "Growth Hacker",
        value: 18,
      },
      {
        name: "Marketing Manager",
        value: 19,
      },
      {
        name: "Content Creator",
        value: 20,
      },
    ],

    "Other Roles": [
      {
        name: "Business Analyst",
        value: 21,
      },
      {
        name: "Data Scientist",
        value: 22,
      },
      {
        name: "Product Manager",
        value: 23,
      },
      {
        name: "Project Manager",
        value: 24,
      },
      {
        name: "Legal",
        value: 25,
      },
    ],

    "Software Engineering": [
      { name: "Mobile Developer", value: 26 },
      { name: "Frontend Developer", value: 27 },
      { name: "Backend Developer", value: 28 },
      { name: "Full-Stack Developer", value: 29 },
      { name: "Engineering Manager", value: 30 },
      { name: "QA Engineer", value: 31 },
      { name: "DevOps", value: 32 },
      { name: "Software Architect", value: 33 },
    ],

    Design: [
      { name: "UI/UX Designer", value: 34 },
      { name: "User Researcher", value: 35 },
      { name: "Visual Designer", value: 36 },
      { name: "Creative Director", value: 37 },
    ],
  };
  return data;
};

/** Returns all related professions in that job family */
export const getAllRelatedJobs = (jobId) => {
  let jobFamiliesData = getJobFamilies();
  let relatedJobs = [];

  Object.keys(jobFamiliesData).map((jobParentName, i) => {
    let jobFamilyData = jobFamiliesData[jobParentName];

    // Inside job family is an array
    jobFamilyData.forEach((jobFamily) => {
      if (jobFamily.value != jobId) return;
      relatedJobs = jobFamilyData;
    });
  });

  return relatedJobs;
};
