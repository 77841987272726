import axios from "axios";

const API_URL = "https://backend.saalari.com/api";

const state = {
  company: {},
  companies: [],
};

const getters = {
  company: (state) => state.company,
  companies: (state) => state.companies,
};

const actions = {
  async fetchCompany( context, id ) {

    let url = encodeURI(`${API_URL}/user/companies/${id}`);

    const response = await axios.get(url);

    context.commit("setCompany", response.data);
  },

  async searchCompany(context, searchQueryObj) {
    let url = encodeURI(
      `${API_URL}/user/companies?searchColumn=${searchQueryObj.name}&searchKeyword=${searchQueryObj.value}`
    );

    const response = await axios.get(url);

    context.commit("setCompanies", response.data);
  },

  async addCompanyEntry(context, data) {
    let url = encodeURI(`${API_URL}/user/company-updates`);

    return await axios.post(url, data);
  },
};

const mutations = {
  setCompany: (state, company) => (state.company = company),
  setCompanies: (state, companies) => (state.companies = companies),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
