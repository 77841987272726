import Vue from 'vue';
import Vuex from 'vuex';
import job from './modules/job';
import salary from './modules/salary';
import company from './modules/company';

// Load Vuex
Vue.use(Vuex);

// Create store
export default new Vuex.Store({
    modules: {
        company, salary, job
    }
});