import Vue from "vue";
import "./styles/main.scss";
import App from "./App.vue";
import router from "./router";
import VueGtag from "vue-gtag";
import VueMeta from "vue-meta";
import store from "./store/index";
import * as VueGoogleMaps from 'vue2-google-maps';

Vue.use(VueGtag, {
  config: { id: "UA-198058078-1" },
});

// Vue Meta
Vue.use(VueMeta, {
  keyName: "head",
});

// Icons
import "./icons";

// Swal
import "./helpers/swal";

// Form Validator
import { applyValidators } from "./helpers/validators";
applyValidators();

Vue.config.productionTip = false;

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyATiOBI3mXDzNldBgwos97Y5Y3G9QYSpDM',
    libraries: 'places'
  }
});

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
