<template>
  <div class="flex items-center justify-center px-6">
    <div class="text-center lg:w-12/9">
      <div class="bg-purple bg-opacity-20 p-8 rounded-md">
        <div class="flex space-x-6">
          <font-awesome-icon
            :icon="['fas', 'envelope']"
            size="lg"
            :style="{ color: '#8A3AB9' }"
          />
          <p>Get the latest salary report in your inbox weekly</p>
        </div>
        <div class="mt-4 mx-auto">
          <FieldButtonCombo
            placeholder="Your Email"
            button_text="Subscribe"
            type="email"
            @data-submitted="emailSubmitted"
            :loading="sending"
          />
        </div>
      </div>
      <MessagePrompt :message="message" @close-prompt="closePrompt" />
    </div>
  </div>
</template>

<script>
import { http } from "@/helpers/http";
import MessagePrompt from "@/components/core/MessagePrompt.vue";
import FieldButtonCombo from "@/components/derived/FieldButtonCombo.vue";

export default {
  name: "CareerBuddyCard",
  components: { FieldButtonCombo, MessagePrompt },
  methods: {
    emailSubmitted(email) {
      this.sending = true;

      // Send Email to Api
      let emailData = JSON.stringify({ email });

      let config = {
        method: "post",
        url: "https://dev-backend.saalari.com/api/user/subscriptions",
        headers: {
          "Content-Type": "application/json",
        },
        data: emailData,
      };

      http(config)
        .then((response) => {
          this.message = response.data.message;
          this.sending = false;
        })
        .catch((error) => {
          this.message = error.message;
          this.sending = false;
        });
    },
    closePrompt() {
      this.message = "";
    },
  },
  data() {
    return {
      sending: false,
      message: "",
    };
  },
};
</script>

<style>
</style>