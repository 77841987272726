<template>
  <div class="container">
    <div class="flex h-screen justify-center items-center">
      <font-awesome-icon icon="spinner" class="text-purple" size="6x" spin />
    </div>
  </div>
</template>

<script>
export default {
  name: "Loading",
};
</script>

<style>
</style>