<template>
  <footer>
    <!-- Career Buddy Footer -->
    <div class="bg-black-brown py-2">
      <div class="container">
        <div class="text-white text-center">
          <div>
            <span>See who is </span>
            <a
              target="_blank"
              href="https://jobs.thecareerbuddy.com/"
              class="font-bold underline"
              >hiring</a
            >
          </div>

          <div class="mt-2">
            <span>
              Start hiring for your
              <a
                target="_blank"
                href="https://thecareerbuddy.com/employers/"
                class="font-bold underline"
                >team</a
              >
            </span>
          </div>

          <div class="mt-2">
            <span
              >Learn more about
              <a
                target="_blank"
                class="font-bold underline"
                href="https://thecareerbuddy.com/"
              >
                Career Buddy
              </a></span
            >
          </div>

          <div class="text-center mt-2">
            <p class="text-white text-center">
              Contact us:
              <a
                class="font-bold underline"
                href="mailto: company@thecareerbuddy.com"
                >company@thecareerbuddy.com</a
              >
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- Main Footer -->
    <div
      class="
        border-dashed
        border
        border-t
        border-b-0
        border-r-0
        border-l-0
        border-black-plain
        mt-1
      "
    >
      <div class="container py-4 flex justify-between">
        <div>
          <p>© Saalari {{ new Date().getFullYear() }}</p>
        </div>
        <div class="flex space-x-8">
          <!-- <font-awesome-icon
            :icon="['fab', 'facebook-f']"
            size="lg"
            :style="{ color: '#3B5998' }"
          /> -->
          <a href="https://twitter.com/saaalari">
            <font-awesome-icon
              :icon="['fab', 'twitter']"
              size="lg"
              :style="{ color: '#00ACEE' }"
            />
          </a>
          <!-- <font-awesome-icon
            :icon="['fab', 'instagram']"
            size="lg"
            :style="{ color: '#8A3AB9' }"
          /> -->
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style>
</style>