<template>
  <div class="container py-8 md:flex justify-between items-center">
    <div class="mb-4 md:mb-0 flex items-center justify-center">
      <div
        class="text-4xl text-center md:text-right font-bold cursor-pointer"
        @click="() => $router.push({ name: 'Home' })"
      >
        <img src="@/assets/logo.png" class="logo" alt="Saalari" />
      </div>
    </div>
    <div v-if="showSearch" class="mb-8 md:mb-0 field-button-container mx-auto">
      <FieldButtonCombo
        button_text="Search Salary"
        type="text"
        placeholder="Search by company, title or location "
        @data-submitted="onSearch"
        rules=""
      />
    </div>
    <div class="flex justify-center">
      <Button
        text="View Salaries"
        @click="() => $router.push({ name: 'Salaries' })"
        custom_class="text-sm"
        variant="secondary"
      />
    </div>
  </div>
</template>

<script>
import Button from "@/components/core/Button.vue";
import FieldButtonCombo from "@/components/derived/FieldButtonCombo.vue";

export default {
  name: "Header",
  components: { Button, FieldButtonCombo },
  data() {
    return {
      showSearch: true,
    };
  },
  methods: {
    onSearch(searchKeyword) {
      this.$router.replace({
        name: "Salaries",
        query: { searchKeyword: searchKeyword },
      });
    },
  },
  watch: {
    $route: function () {
      if (this.$route.name === "Salaries") return this.showSearch = false;
      return this.showSearch = true;
    },
  },
};
</script>

<style scoped lang="scss">
.field-button-container {
  max-width: 486px;
  width: 100%;
}

.logo{
  width: 200px;
}
</style>