import axios from 'axios';

const state = {
    salaries: []
}

const getters= {
    salaries: (state) => state.salaries
}

const actions = {
    async fetchSalaries(context, companyId) {
        const response = await axios.get(encodeURI(`https://backend.saalari.com/api/user/salaries?searchColumn=companyId&searchKeyword=${companyId}&searchType=eq`));
        console.log(encodeURI(`https://backend.saalari.com/api/user/salaries?searchColumn=companyId&searchKeyword=${companyId}&searchType=eq`));
        console.log(response.data);

        context.commit('setSalaries', response.data);
    }
}

const mutations = {
    setSalaries: (state,salaries) => (state.salaries = salaries)
}

export default {
    state, getters, actions, mutations
}