<template>
  <transition name="fade">
    <div
      v-if="message"
      class="mt-2 flex justify-between items-center rounded p-2 bg-purple text-white"
    >
      <p>{{ message }}</p>
      <font-awesome-icon
        :icon="['fas', 'times-circle']"
        class="cursor-pointer"
        @click="$emit('close-prompt')"
      />
    </div>
  </transition>
</template>

<script>
export default {
  name: "MessagePrompt",
  props: {
    message: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>