import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/add-salary",
    name: "Add Salary",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "add_salary" */ "../views/AddSalary.vue"),
  },
  {
    path: "/salaries",
    name: "Salaries",
    component: () =>
      import(/* webpackChunkName: "salaries" */ "../views/Salaries.vue"),
  },
  {
    path: "/add-company-entry/:id",
    name: "AddCompanyEntry",
    component: () => import('../views/AddCompanyEntry.vue')
  },
  {
    path: "/company/:companyName",
    name: "CompanySingle",
    component: () => import("../views/CompanySingle.vue"),
  },
  {
    path: "/add-company-update",
    name: "AddCompanyUpdate",
    component: () =>
      import("../views/AddCompanyUpdate.vue"),
  },
  {
    path: "/jobs/:id",
    name: "JobsSingle",
    component: () => import("../views/JobSingle.vue"),
  },
  {
    path: "/:catchAll(.*)",
    redirect: "404",
  },
  {
    path: "/404",
    name: "NotFound",
    component: () =>
      import(/* webpackChunkName: "not_found" */ "../views/PageNotFound.vue"),
  },
];

const router = new VueRouter({
  routes,
});

export default router;
