import Vue from "vue";

import VueSweetalert2 from "vue-sweetalert2";

// Swal Styles
import "sweetalert2/dist/sweetalert2.min.css";

const options = {
    confirmButtonColor: '#5F4CA5'
}

Vue.use(VueSweetalert2, options);
