<template>
  <router-link :to="{name: 'JobsSingle', params: {id: data.jobFamily}}">

  <div v-if="data.jobFamily" class="mb-8 inline-flex items-center space-x-6 shadow bg-gray-main p-6 rounded-md">
    <div
      class="rounded-full flex items-center justify-center bg-purple h-16 w-16"
    >
      <span class="text-xl text-white font-bold">{{ count + 1 }}</span>
    </div>
    <div>
      <p class="font-bold text-lg">{{ getJobFamilyName(data.jobFamily) }}</p>
    </div>
  </div>

  </router-link>
</template>

<script>
import {jobFamilies} from "@/helpers/enums";

export default {
  name: "TopTenPayingJobsCard",
  props: {
    data: {
      type: Object,
      required: true,
    },
    count: Number,
  },
  methods: {
    getJobFamilyName(code){
      return jobFamilies(code);
    }
  }
};
</script>

<style scoped lang="scss">
</style>