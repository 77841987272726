<template>
  <router-link :to="{name: 'CompanySingle', params: {companyName: data.name}}">
    <div
      class="
        mb-8
        inline-flex
        items-center
        space-x-6
        shadow
        bg-gray-main
        p-6
        rounded-md
      "
    >
      <div
        class="
          rounded-full
          flex
          items-center
          justify-center
          bg-purple
          h-16
          w-16
        "
      >
        <span class="text-xl text-white font-bold">{{ count + 1 }}</span>
      </div>
      <div>
        <p class="font-bold text-lg">{{ data.name }}</p>
        <small
          v-if="data.jobLocation"
          class="text-xs text-black-light font-light"
          >{{ data.jobLocation }}</small
        >
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  name: "TopTenCard",
  props: {
    data: {
      type: Object,
      required: true,
    },
    count: Number,
  },
};
</script>

<style scoped lang="scss">
</style>