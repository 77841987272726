// Font Awesome
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import Vue from "vue";

import {
  faFacebookF,
  faInstagram,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import {
  faPlusCircle,
  faArrowCircleRight,
  faHome,
  faDollarSign,
  faEnvelope,
  faPlus,
  faCalendar,
  faCheckDouble,
  faArrowRight,
  faTimesCircle,
  faSpinner,
  faFilter,
  faGlobe,
  faUsers
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faFacebookF,
  faInstagram,
  faTwitter,
  faPlusCircle,
  faArrowCircleRight,
  faTimesCircle,
  faEnvelope,
  faHome,
  faPlus,
  faDollarSign,
  faSpinner,
  faArrowRight,
  faFilter,
  faCheckDouble,
  faGlobe,
  faUsers,
  faCalendar
);

Vue.component("font-awesome-icon", FontAwesomeIcon);
