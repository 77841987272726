<template>
  <div class="flex items-center justify-center">
    <div class="text-center lg:w-2/5">
      <div class="bg-purple bg-opacity-20 pb-8 px-8 rounded-md">
        <div class="mb-4">
          <img class="carrer-buddy-logo" src="@/assets/buddy.png" />
        </div>
        <h2 class="text-3xl font-bold">Job hunting?</h2>
        <p class="my-4">
          From software engineers to operations managers and UX designers,
          CareerBuddy’s here to help you find your unicorn
          <span class="text-purple">role.</span>
        </p>
        <div class="flex justify-center">
          <a class="bg-white shadow p-4 rounded flex items-center" target="_blank" href="https://jobs.thecareerbuddy.com/">
            Visit <img class="w-32 mx-2" src="@/assets/buddy-logo.png" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: "CareerBuddyCard"
};
</script>

<style scoped>
.carrer-buddy-logo{
  margin-top: -3px;
}
</style>