<template>
  <div v-if="errorMessage" class="container error-container">
    <div class="text-center my-12">
      <p class="text-4xl  text-red-500 font-bold">{{ errorMessage }}</p>
      <div class="my-12 flex justify-center">
        <img src="@/assets/error.svg" class="error-image" alt="Error" />
      </div>
      <div class="mt-6">
        <p class="md:w-1/2 mx-auto">Try and refresh the page. Please contact the site admin. If this error persists.</p>
      </div>
      <p></p>
    </div>
  </div>
</template>

<script>
export default {
  name: "ErrorMessage",
  props: {
    errorMessage: String,
  },
};
</script>

<style scoped>
.error-image {
  width: 200px;
}


</style>