import Vue from "vue";
import moment from "moment";
import { validate } from "json-schema";

export const FILTERS = {
  formatDate: (value) => {
    return value ? moment(value).format("DD MMM YYYY") : "";
  },

  formatDateTime: (value) => {
    return value ? moment(value).format("DD MMM YYYY, hh:mm:ss a") : "";
  },

  formatTotalSalary: (value) => {

    value = parseInt(value);

    if (!value) return "";

    //   If not a million
    let millionDigitCount = 7;
    let hundreadmillionDigitCount = 9;

    if (value.toString().length < millionDigitCount || value.toString().length > hundreadmillionDigitCount ) return addCommasToCurrency(value);

    let roundedNumberString = Math.round(value).toString();
    let million = convertToMillion(roundedNumberString);
    return million;
  },

  getTimeDifference(pastDate) {
    let now = moment();
    let past = moment(pastDate);

    let difference = now.diff(past);

    let tempTime = moment.duration(difference);

    let hours = tempTime.hours();
    let minutes = tempTime.minutes();
    let days = tempTime.days();

    if (hours < 1 && days < 1) return `${minutes} minutes ago`;

    if (days >= 1) return `${days} days ago`;

    if (hours >= 1) return `${hours} hours ago`;
  },

  formatStock: (value) => {
    if (!value) return "";

    let stringValue = value.toString();
    let length = stringValue.length;

    // Thousand
    if (length >= 4 && length <= 6) return convertToThousand(stringValue);
    // Million
    if (length > 6 && length <= 9) return convertToMillion(stringValue);

    return addCommasToCurrency(value);
  },
};

const convertToThousand = (value) => {
  if (!value) return "";

  let length = value.length;
  if (length == 4) return value.slice(0, 1) + "k";
  if (length == 5) return value.slice(0, 2) + "k";
  if (length == 6) return value.slice(0, 3) + "k";
};

const convertToMillion = (value) => {
  if (!value) return "";

  let length = value.length;

  // Check for 0
  // Return 1m instead of 1.0m
  if (length == 7)
    return (
      value.slice(0, 1) +
      (value.slice(1, 2) == "0" ? "" : "." + value.slice(1, 2)) +
      "m"
    );
  if (length == 8) return value.slice(0, 2) + "m";
  if (length == 9) return value.slice(0, 3) + "m";
};

const addCommasToCurrency = (value) => {
  if (!value) return "";

  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const registerFilters = function () {
  Object.keys(FILTERS).forEach((key) => {
    Vue.filter(key, FILTERS[key]);
  });
};
