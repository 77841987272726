<template>
  <div>
    <ErrorMessage :errorMessage="errorMessage" />
    <template v-if="!errorMessage">
      <div>
        <div class="home">
          <div v-if="loadingRecentSalary">
            <Loading />
          </div>
          <RecentSalarySubmissions
            v-else
            :recentSalaryData="recentSalaryData"
          />
        </div>
        <div class="container py-16">
          <header class="mb-16">
            <h2 class="text-3xl font-bold">Top 10</h2>
            <p class="text-xl">Paying Companies</p>
          </header>

          <div v-if="loadingTop10PayingCompanies">
            <Loading />
          </div>

          <div
            v-else
            class="flex flex-wrap space-x-6 items-center justify-center w-full"
          >
            <template v-for="(company, index) in topTenPayingCompanies">
              <TopTenCard :data="company" :count="index" :key="index" />
            </template>
          </div>
        </div>
        <div class="mb-16">
          <div class="container">
            <CareerBuddyCard />
          </div>
        </div>
        <div class="container pb-16 mt-8">
          <header class="mb-16">
            <p class="text-xl">Paying Jobs</p>
          </header>

          <div v-if="loadingTop10PayingJobs">
            <Loading />
          </div>

          <div
            v-else
            class="flex flex-wrap space-x-6 items-center justify-center w-full"
          >
            <template v-for="(jobs, index) in topTenPayingJobs">
              <TopTenPayingJobsCard :data="jobs" :count="index" :key="index" />
            </template>
          </div>
          <div class="flex justify-center mt-6">
            <Button
              text="View More Salaries Data"
              @click="() => this.$router.push({ name: 'Salaries' })"
            />
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { http } from "@/helpers/http";
import Button from "../components/core/Button";
import Loading from "../components/core/Loading.vue";
import TopTenCard from "../components/core/TopTenCard";
import ErrorMessage from "../components/derived/ErrorMessage.vue";
import CareerBuddyCard from "../components/derived/CareerBuddyCard";
import TopTenPayingJobsCard from "../components/core/TopTenPayingJobsCard";
import RecentSalarySubmissions from "@/components/derived/RecentSalarySubmissions";

export default {
  name: "Home",
  async mounted() {
    // Top 10 Paying Jobs
    try {
      const top10ApiResponse = await http.get(
        "/api/user/salaries/top-paying-jobs",
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      this.topTenPayingJobs = top10ApiResponse.data;
      // console.log(this.topTenPayingJobs);
      this.loadingTop10PayingJobs = false;
    } catch (error) {
      this.onError(error);
    }

    // Top 10 Paying Companies
    try {
      const top10PayingCompaniesApiResponse = await http.get(
        "/api/user/companies?sort=avgSalary%7Cdesc&page=1&per_page=10",
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      this.topTenPayingCompanies = top10PayingCompaniesApiResponse.data.data;
      this.loadingTop10PayingCompanies = false;
    } catch (error) {
      this.onError(error);
    }

    // Recent Salary Submissions
    try {
      const recentSalariesResponse = await http.get(
        "/api/user/salaries?per_page=5&sort=createdAt|desc&currency=ngn",
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      this.loadingRecentSalary = false;
      this.recentSalaryData = recentSalariesResponse.data.data;
    } catch (error) {
      this.onError(error);
    }
  },

  components: {
    RecentSalarySubmissions,
    TopTenCard,
    CareerBuddyCard,
    Button,
    Loading,
    ErrorMessage,
    TopTenPayingJobsCard,
  },
  data: function () {
    return {
      topTenPayingJobs: [],
      topTenPayingCompanies: [],
      recentSalaryData: [],
      loadingRecentSalary: true,
      loadingTop10PayingJobs: true,
      loadingTop10PayingCompanies: true,
      errorMessage: "",
    };
  },
  methods: {
    onError(error) {
      this.loadingRecentSalary = false;
      this.loadingTop10PayingJobs = false;
      this.loadingTop10PayingCompanies = false;
      this.errorMessage = error.message;
    },
  },
};
</script>

